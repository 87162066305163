<template>
  <v-container fluid>

    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 py-6' : 'px-1 py-1'">

        <v-card>
          <v-card-title class="black--text px-6">
            <v-icon class="mr-2 text--text">mdi-format-list-bulleted-triangle</v-icon> Telemetri Station List
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
          :headers="headersList"
          :items="newList"
          :items-per-page="5"
          class="elevation-1 px-6 admin_table"
          >
            <template v-slot:item.dataValidationStatus>
            <v-chip
                color=orange
                dark
            >
            Not Process
            </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <template>
                    <v-icon
                      small
                      class="mr-2 blue--text"
                      @click="formRequest(item)"
                  >mdi-pencil</v-icon
              >
              </template>
            </template>
          </v-data-table>

        </v-card>
    
    </v-container>
    
    <v-dialog 
    v-model = "formApplication" 
    max-width="1000"
    >
      <v-card class="px-8 form">
          <v-card-title class="primary--text">Application Information</v-card-title>
          <v-card-text>
              <v-row>
                  <v-col cols="3">
                      Full Name
                  </v-col>
                  <v-col cols="1">
                      :
                  </v-col>

                  <v-col cols="6">
                      {{detailForm.name}}
                  </v-col>

                  <v-col cols="3">
                      Ministry / Agency 
                  </v-col>
                  <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      {{detailForm.name}}
                  </v-col>

                  <v-col cols="3">
                      Phone No.
                  </v-col>
                  <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      {{detailForm.phoneNo}}
                  </v-col>

                  <v-col cols="3">
                      Email Name 
                  </v-col>
                  <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      {{detailForm.email}}
                  </v-col>

                  <v-col cols="3">
                      Designation 
                  </v-col>
                  <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      {{detailForm.designation}}
                  </v-col>

              </v-row>
          </v-card-text>

          <v-card-title class="primary--text">About Data Sharing</v-card-title>
          <v-card-text>
          <v-row>
                <v-col cols="3">
                      Full Name 
                  </v-col>
                    <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      {{detailForm.name}}
                  </v-col>

                    <v-col cols="3">
                      Supporting Document 
                  </v-col>
                    <v-col cols="1">
                      :
                  </v-col>
                  <v-col cols="6">
                      document.docx
                  </v-col>
          </v-row>
          </v-card-text>

          <v-card-title class="primary--text">Water Quality Station</v-card-title>

          <template>
              <v-simple-table>
                  <template v-slot:default>
                  <thead>
                      <tr>
                          <th class="text-center"  
                              v-for="item in headersList2"
                              :key="item.name"
                          >
                              {{ item.text}}
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr
                      class="text-center">
                      
                      <td>{{ newFormDetails.agency }}</td>
                      <td>{{ newFormDetails.stationID }}</td>
                      <td>{{ newFormDetails.location }}</td>
                      <td> <v-select
                          v-model = "formStatus"
                          :items="statusForm"
                          solo
                          dense
                          hide-details
                          class="my-5"
                          ></v-select></td>
                      <td>  <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              item-text="newFormDetails.status"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  solo
                                  dense
                                  hide-details
                                  class="my-5"
                              >{{newFormDetails.expiredDate}}</v-text-field>
                              </template>
                              <v-date-picker
                              v-model="date"
                              @input="menu2 = false"
                              ></v-date-picker>
                          </v-menu></td>
                      <td> <v-text-field
                          :items="statusForm"
                          solo
                          dense
                          hide-details
                          class="my-5"
                          >{{newFormDetails.remarks}}</v-text-field></td>


                      </tr>
                  </tbody>
                  </template>
              </v-simple-table>
              <br>
              
              <v-row>
                  <v-col  class="px-8" cols="9">

                  </v-col>
                  <v-col  class="px-2" cols="1">
                      <v-btn
                      elevation="2"
                      color="grey"
                      @click="formApplication= false"
                      >Cancel</v-btn>
                  </v-col>

                  <v-col  class="px-8" cols="1">
                        <v-btn
                          elevation="2"
                          color= "primary"
                          @click="formApplication= false"
                      >Proceed</v-btn>
                      
                  </v-col>
              </v-row>

              <br>

              
              </template>


      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import axios from "axios";
import appMixins from '@/mixins/globalMixins'
import {bus} from '@/main'

export default {

  data: () => ({

    formApplication: false, 
    detailForm: {},
    statusForm: ["Lulus", "Tolak"],
    formStatus: "Lulus",
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    countList: 0,

    headersList: [
        {
          text: 'REFERENCE NO.',
          align: 'start',
          sortable: false,
          value: 'idUser',
        },
        { text: 'EMAIL', value: 'email' },
        { text: 'REQUEST DATE & TIME', value: 'lastPwdChange' },
        { text: 'STATUS', value: 'dataValidationStatus' },
        { text: 'FORM', value: 'actions' },
      ],
    headersList2: [
          {
            text: 'Agency',
            align: 'start',
            sortable: false,
            value: 'idUser',
          },
          { text: 'Station ID', value: 'email' },
          { text: 'Location', value: 'lastPwdChange' },
          { text: 'Status', value: 'dataValidationStatus' },
          { text: 'Expired Date', value: 'actions' },
          { text: 'Remarks', value: 'dataValidationStatus' },
        ],
    newFormDetails: {
        agency: "JAS",
        stationID: "CMS02",
        location: "SUNGAI PERAK",
        Status: "Lulus",
        expiredDate: '07-02-2023',
        remarks: "Contaminated"
    },
    newList: [],

  }),

  mounted() {
    console.log("welcome home");

    this.getAllUser();
  },

  methods: {

    getAllUser() {
      axios
        .get(appMixins.data().globalUrl+`/sarawak/users/all`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.newList = response.data;
          this.countList = this.newList.length;
          bus.$emit("showBadge", this.countList);
          console.log(this.userData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formRequest(item){
        this.formApplication = true;
        console.log(this.formApplication, item);
        this.detailForm = item;
    }
    
  },

  created() {
    bus.$on("showBadge", (countList) => {
      this.badgeCount = countList;
    });
  }

};
</script>

<style lang="scss">
@import '~scss/main';

.background{
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

// .v-list-item >  .v-list-item__icon > .v-badge{
//     height: 100px;
// }

// .v-badge__badge  {
  
//   height: 20px;
//   font-weight: bold;
//   font-size: small !important;
//   min-width: 20px;
//   color: white !important; 
//   margin-left: 8px; 
//   padding: .5px 3px .5px 3px; 
//   text-align: center; 
//   vertical-align: middle;
// }


</style>


